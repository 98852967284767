import React from "react"
import SEO from "../../../components/seo"
// import { Helmet } from "react-helmet"
import Layout from "../../../components/layout"
import TwoColumn from "../../../components/two-column"
import CustomerJourney from "../../../components/customer-journey"
// import { lifecycleContent } from "../../../lib/lifecycle-content"
import Lifecycle from "../../../components/lifecycle"
import Preview from '../../../components/preview'

const bannerContent = {
  title: "Done-for-you account management saves you dev hours and money.",
  description: [
    {
      text: "Just like Billsby Checkout, our full account management stack can be added to your website with just two lines of code - you don't even need to build login and authentication unless you want to. It's the quickest account management going."
    }
  ],
  buttons: [
    {
      isExternal: true,
      url: 'https://app.billsby.com/registration',
      buttonColor: 'orange',
      buttonText: 'Sign up for free'
    },
    {
      scheduleButton: true,
      buttonColor: 'black'
    }
  ],
  // sectionDevelopers: true,
  sectionSubscriberBanner: true,
  svg: true,
  image: require('../../../images/in-life-account-management.svg'),
  imageName: 'in life account management svg',
  backgroundColor: '#f3f5fb',
  textColor: '#252422',
  imagePosition: 'right',
  sectionFeaturetag: true,
}

const interactivePreviewContent = {
  previewBlackBackground: true,
  title: "Try our account management tools for yourself",
  description: <>Choose the 'Manage account' button below to see our self-service tools in action. You'll need to have a Beach account to give this one a try. If you don't have one, you can <a href="javascript:void(0)" data-billsby-type="checkout">create a subscription</a> and then use the button below.</>,
  preview: 
  <>
    <div className="preview interactive-preview">
      <div className="preview-container">
        <div className="col-2" style={{ backgroundImage: `url(${require('../../../images/preview-img1@2x.png')})`}}>
        </div>
        <div className="col-2 gray-bg">
          <p className="preview-text">
            Beautiful <br/>
            swimwear <br/>
            delivered monthly.
          </p>
          <h4 className="preview-heading">Beach</h4>
          <a a href="javascript:void(0)" data-billsby-type="account" className="btn btn-violet preview-button">Manage account</a>
        </div>
        <div className="col-3" style={{ backgroundImage: `url(${require('../../../images/preview-img2@2x.png')})`}}>
        </div>
        <div className="col-3" style={{ backgroundImage: `url(${require('../../../images/preview-img3@2x.png')})`}}>
        </div>
        <div className="col-3" style={{ backgroundImage: `url(${require('../../../images/preview-img4@2x.png')})`}}>
        </div>
      </div>
    </div>

    <div className="text-holder">
      <p className="text">The whole account management toolset is delivered with just two lines of code - one piece of JavaScript in the header (the same one as you use for Billsby Checkout) and then another for the manage account link.</p>
    </div>

    <div className="code-holder">
      <pre>
       <code>
       &lt;script src="https://checkoutlib.billsby.com/checkout.min.js" data-billsby-company="<span className="orange-text">companyname</span>">&lt;/script>
       </code>
      </pre>
    </div>

    <div className="code-holder">
      <pre>
       <code>
       &lt;a href="javascript:void(0)" data-billsby-type="account">Manage account&lt;/a>
       </code>
      </pre>
    </div>
    
    <div className="text-holder">
      <p className="text">So if you can install Billsby Checkout, adding account management takes seconds.</p>
    </div>
  </>
}


const lifecycleContent = {
  title: "Comprehensive account management functionality",
  block: [
    {
      img: require('../../../images/change-plans.svg'),
      text: "Change plans",
    },
    {
      img: require('../../../images/edit-details.svg'),
      text: "Update details and addresses"
    },
    {
      img: require('../../../images/change-payment-card.svg'),
      text: "Change payment card"
    },
    {
      img: require('../../../images/add-ons-and-allowances.svg'),
      text: "Manage add-ons and allowances"
    },
    {
      img: require('../../../images/management-invoices.svg'),
      text: "View invoices"
    },
    {
      img: require('../../../images/delete.svg'),
      text: "Cancel plans"
    },
  ]
}

const previewContent = {
  title: "Authenticate customers and deep-link to specific functions",
  description: "With a little extra time, you can authenticate customers so they don't need a one-time passcode, or deep-link to specific account management functions to design your own custom menu systems.",
  preview: 
  <>
    <div className="preview preview-layout-2 disabled">
      <div className="preview-container">
        <div className="col-24" style={{ backgroundImage: `url(${require('../../../images/preview-img5@2x.png')})`}}>
        </div>
        <div className="col-76 gray-bg">
          <a className="btn btn-violet btn-sm btn-logout">Logout</a>
          <h4 className="preview-heading">Beach</h4>
          <p className="preview-text">
            Beautiful <br/>
            swimwear <br/>
            delivered monthly.
          </p>
          <div className="button-wrapper">
           <div className="btn-holder">
            <a className="btn btn-violet btn-sm">Change payment method</a>
           </div>
           <div className="btn-holder">
            <a className="btn btn-violet btn-sm">View billing history</a>
           </div>
           <div className="btn-holder">
            <a className="btn btn-violet btn-sm">Update shipping address</a>
           </div>  
           <div className="btn-holder">
            <a className="btn btn-red btn-sm">Cancel Beach</a>
           </div>
          </div>
          {/* <a href="javascript:void(0)" data-billsby-type="checkout" className="btn btn-violet preview-button">Manage account</a> */}
        </div>
      </div>
    </div>

    <div className="text-holder">
      <p className="text">Delivering authenticated account management and deep-linking is as simple as embedding the customers unique identifier and the specific function you want to display in the link.</p>
    </div>

    <div className="code-holder">
      <pre>
       <code>
       &lt;a href="javascript:void(0)" data-billsby-type="account" data-billsby-action="<span className="orange-text">cancelplan</span>" data-billsby-customer="<span className="orange-text">CustomerID</span>">Cancel plan&lt;/a>
       </code>
      </pre>
    </div>
    
  </>
}

const twoColumnContent = {
  title: "It's not all or nothing. Turn off the self-sevice features you don't.",
  description: [
    {
      text: "You might decide that for certain features, you'd rather talk to customers yourself - like if they want to cancel. Or, you might want them to update their address in your system and then send the change to us through the API, but view invoices and update their credit card using the account management modal."
    },
    {
      text: "That's fine. You can turn off whichedver features you don't want customers to use, and you can still invoke them on an ad-hoc basis with deep linking."
    },
    {
      text: "You're in control."
    }
  ],
  image: 'account-management-screenshot.png',
  imageName: 'account management screenshot',
  backgroundImage: require('../../../images/account-management-screenshot@2x.png'),
  backgroundColor: '#243f5c',
  textColor: '#fff',
  imagePosition: 'full-right',
  sectionReport: true,
  sectionReport3: true,
}


const apiContent = {
  title: "If you can do it with Billsby, you can do it with our powerful API.",
  backgroundColor: '#fff',
  list: [
    {
      title: 'Build your own account management journeys',
      description: "Everything that works in our account management modal can also be custom built by you with our API to create a headless billing solution.",
    },
    {
      title: 'Synchronise data across systems',
      description: "Receive update web hooks from us when something changes, or push changes to us from your own app or third party tools."
    },
    {
      title: 'Mix and match',
      description: "Take some elements of our pre-built account management tools and mix them with API features to balance development hours with custom design."
    }
  ],
  svg: true,
  image: require('../../../images/api-relay-race.svg'),
  imageName: 'api relay race',
  imagePosition: 'bottom'
}

const AccountManagement = () => (
  <Layout className="subscriber-account-management">
    <SEO 
      title="Account Management | Billsby | Powerful, customizable subscription billing software" 
      description="Done-for-you account management saves you dev hours and money when integrating subscription billing and recurring payments software into your business." 
      url="https://www.billsby.com/product/subscribers/account-management"
    />

    <TwoColumn content={bannerContent}/>
    <Preview content={interactivePreviewContent}/>
    {/* <div>
      <script src="https://checkoutlib.billsby.com/checkout.min.js" data-billsby-company="beach"></script>
    </div> */}
    <Lifecycle content={lifecycleContent} />
    <Preview content={previewContent}/>
    <TwoColumn content={twoColumnContent}/>
    <CustomerJourney content={apiContent} />
  </Layout>
)

export default AccountManagement