import React from "react"

const lifecycle = ({content}) => {
  return (
    <div className="lifecycle">
      <div className="container">
        <p className="title">{content.title}</p>
        <div className="lifecycle-flex">
          {
            content.block.map((item, i) => {
              return(
                <div className="block" key={i}>
                  <img src={item.img} alt={item.text} />
                  <p className="text">{item.text}</p>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default lifecycle